const header = {
  // all the properties are optional - can be left empty or deleted
  //  homepage: 'https://rajshekhar26.github.io/cleanfolio',
  title: 'JS.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Supem Maduwantha',
  role: 'Front End Developer',
  description:
    'Passionate and skilled front end developer with a knack for creating visually appealing and user-friendly websites. Experienced in HTML, CSS, JavaScript, and modern frameworks like React. Adept at transforming design concepts into responsive, interactive web applications. Committed to writing clean, efficient code and continuously learning new technologies to improve user experience.',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/supem-m-99aba8234/',
    github: 'https://github.com/supem137',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Weather Application',
    description:
      'A sleek weather app delivering real-time forecasts, alerts, and detailed climate data for your location',
    stack: ['React', 'Tailwind CSS', 'REST API'],
    sourceCode: 'https://github.com/supem137/weather-app_ReactJS',
    livePreview: 'https://weather-app-reactjs.pages.dev/',
  },
  {
    name: 'Countries Guide',
    description:
      'Discover global insights with our countries guide app, offering key facts and cultural highlights effortlessly.',
    stack: ['React', 'Redux', 'REST API'],
    sourceCode: 'https://github.com/supem137/Countries_Guide_Reactjs',
    livePreview: 'https://countries-guide-reactjs.pages.dev/',
  },
  {
    name: 'Expense Tracker',
    description:
      'Expense Tracker app helps users manage and track their spending with ease and efficiency.',
    stack: ['React', 'MUI', 'Chart Js'],
    sourceCode: 'https://github.com/supem137/expense-traking-app_ReactJS',
    livePreview: 'https://expense-traking-app-reactjs.pages.dev/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'C#',
  'TypeScript',
  'React',
  'Redux',
  'SASS',
  'Material UI',
  'Git',
  'Github',
  'Figma',
  'Tailwind CSS',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'supem.maduwantha2014@gmail.com',
}

export { header, about, projects, skills, contact }
