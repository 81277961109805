import Navbar from '../Navbar/Navbar'
import './Header.css'
import Logo from '../../assets/Logo'

const Header = ({ themeName }) => (
  <header className='header center'>
    {themeName === 'dark' ? <Logo fill='#90a0d9' /> : <Logo fill='#2978b5' />}
    <Navbar />
  </header>
)

export default Header
